.table {
  .row {
    justify-content: center;
  }

  thead {
    > tr:first-child {
      display: none;
    }

    > tr th {
      background: white;
      position: sticky;
      top: 0;
    }
  }

  .nav-link {
    padding: 0;
  }

  #difficultyProgress {
    margin-bottom: 25px;
    font-size: 11.5px;
  }

  #difficultyProgress > div:nth-child(n + 2) {
    margin-top: 12px;
  }

  .progress-pie {
    height: 75px;
  }

  .progress-bar-sm {
    height: 5px;
  }

  .easy {
    background-color: #5cb85c;
  }

  .medium {
    background-color: #f0ad4e;
  }

  .hard {
    background-color: #d9534f;
  }

  .companies,
  .patterns {
    justify-content: space-evenly;
    margin: 0;
  }

  .companies img {
    padding: 5px;
  }

  .react-toggle {
    top: 7px;
  }

  .reset-button {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 0.7rem;
  }
}
