.pattern-count {
  padding: 0.75em;

  h5 {
    font-weight: bold;
  }

  .badge {
    margin: 0.25em 0.25em;
  }
}
