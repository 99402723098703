.acknowledgements {
  justify-content: center;

  .row {
    justify-content: center;
  }

  h1 {
    text-align: center;
  }

  .nav-link {
    padding: 0;
  }
}
