@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');

.App {
  margin-left: calc(100vw - 100%);
  margin-right: 0;

  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased !important;
}
